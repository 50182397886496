import React from "react";
// import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { loading } = useUser(); // Get user and loading state from context
  // const location = useLocation();
  if (loading) {
    return <div>Loading...</div>; // Show loading while fetching user data
  }

  // if (Object.keys(user).length === 0) {
  //   localStorage.setItem("redirectTo", location.pathname);
  //   console.log(location.pathname);
  //   return <Navigate to="/login" replace />; // If user is not authenticated, redirect to login
  // }

  return <>{children}</>; // If authenticated, render children components
};

export default ProtectedRoute;
