// src/pages/ToolPurchase.tsx
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ActionButton from "../components/ActionButton";
import LoadingSpinner from "../components/LoadingSpinner";
import Notification from "../components/Notification"; // Import notification component

import PaystackPop from "@paystack/inline-js";

// Mock data for the tool based on ID
const toolData = {
  id: 2,
  name: "CashApp Clone (iPhone)",
  description:
    "Paid version of the CashApp clone for iPhone. Requires UUID for IPA build.",
  price: "$99",
};

// Custom hook to handle Paystack messages
const usePaystackEventHandler = (handlePaymentClosed, handlePaymentSuccess) => {
  useEffect(() => {
    const handlePaystackMessage = (event) => {
      // Ensure the event comes from Paystack
      if (!event.origin.includes("paystack.co")) return;

      // Define the different event types Paystack might send
      const paystackEventTypes = {
        loaded: "loaded:transaction",
        close: "close",
        success: "success",
      };

      const { event: eventType } = event.data;

      if (eventType === paystackEventTypes.close) {
        handlePaymentClosed();
      }

      if (eventType === paystackEventTypes.success) {
        // Trigger success handler (e.g., confirmation from backend)
        handlePaymentSuccess();
      }
    };

    // Listen for postMessage events
    window.addEventListener("message", handlePaystackMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("message", handlePaystackMessage);
    };
  }, [handlePaymentClosed, handlePaymentSuccess]);
};

const ToolPurchase = () => {
  const [notification, setNotification] = useState(null);
  const [paymentData, setPaymentData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading, please wait...");

  // This function will handle what happens after successful payment
  const handlePaymentSuccess = async () => {
    if (!navigator.onLine) {
      setNotification({
        message: "No internet connection. Please check your network.",
        type: "success",
      });
    } else {
      setLoadingText("Verifying Payment, please wait...");
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://bright-mink-vaguely.ngrok-free.app/paystack/verify-payment/${paymentData.reference}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Invalid token");
        }

        const data = await response.json();
        console.log(data);
        setIsLoading(false);
        setNotification({ message: "Payment Sucess", type: "success" });
      } catch (err) {
        console.log(err.message);
      }
    }

    // Additional logic can go here, like backend confirmation via API call
  };
  const handlePaymentClosed = async () => {
    setNotification({ message: "You Canceled the payment", type: "error" });
    // Additional logic can go here, like backend confirmation via API call
  };

  // Use the custom hook to handle Paystack messages
  usePaystackEventHandler(handlePaymentClosed, handlePaymentSuccess);

  const { id } = useParams();
  // const [error, setError] = useState(null); // State to manage login error
  // const [loading, setLoading] = useState(false);
  // console.log(id);
  const [uuid, setUuid] = useState(""); // User's UUID state

  const handleUuidSubmit = async () => {
    if (uuid) {
      // Handle UUID submission logic, like validating the UUID format
      if (!navigator.onLine) {
        console.log("No internet connection. Please check your network.");
      } else {
        setIsLoading(true);
        try {
          const response = await fetch(
            `https://bright-mink-vaguely.ngrok-free.app/paystack/initialize/${id}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Invalid email or password");
          }

          const data = await response.json();

          const popup = new PaystackPop();
          setPaymentData(data.data);
          setIsLoading(false);
          popup.resumeTransaction(data.data.access_code);
        } catch (err) {
          console.log(err.message);
          setIsLoading(false);
        }
      }
      // Proceed to payment...
    } else {
      alert("Please enter a valid UUID.");
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="hidden sm:block md:w-64"></div>
      <div className="p-8">
        <h1 className="text-3xl font-bold mb-4">{toolData.name}</h1>
        <p className="text-gray-700 mb-6">{toolData.description}</p>
        <p className="text-lg font-semibold mb-6">Price: {toolData.price}</p>

        <div className="mb-4">
          <label htmlFor="uuid" className="block text-gray-700 font-semibold">
            Enter your iPhone UUID (Find it using iTunes or 3uTools):
          </label>
          <input
            type="text"
            id="uuid"
            className="w-full p-2 border border-gray-300 rounded mt-1"
            value={uuid}
            onChange={(e) => setUuid(e.target.value)}
            placeholder="Enter your device UUID"
            required
          />
        </div>

        <ActionButton
          onClick={() => handleUuidSubmit()}
          className="bg-blue-500 text-white py-2 px-4 rounded"
          name="Submit and Pay"
        />
      </div>
      {/* Notification component */}
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)} // Hide after the notification disappears
        />
      )}
      <div>{isLoading && <LoadingSpinner message={loadingText} />}</div>
    </div>
  );
};

export default ToolPurchase;
