// src/pages/Home.tsx
import React from "react";
import FeaturedTools from "../components/FeaturedTools";
import Testimonials from "../components/Testimonials";
import "../App.css";
import { useNavigate } from "react-router-dom"; // Use navigate for redirection

const Home: React.FC = () => {
  const navigate = useNavigate();
  const handleSignInClick = (e: any) => {
    e.preventDefault();
    const currentHost = window.location.host; // Gets the current host (e.g., localhost:3000)

    // Check if it's already a dashboard subdomain, if not, append it
    if (!currentHost.startsWith("dashboard")) {
      const newHost = `dashboard.${currentHost}`;
      window.location.href = `http://${newHost}/login`; // Redirect to the subdomain with /login
    } else {
      navigate("/login"); // If already on dashboard subdomain, just navigate to login
    }
  };

  return (
    <div>
      <main>
        <section
          className="bg-cover bg-center items-center py-20 h-screen flex justify-center"
          style={{
            backgroundImage: "url('/background.webp')",
          }}
        >
          <div className="container mx-auto text-center">
            <h1 className="text-4xl font-bold text-chantilly">
              Learn, Understand, and Defend Social Engineering
            </h1>
            <p className="mt-4 text-lg text-white">
              Providing tools to raise awareness and protect against social
              engineering attacks.
            </p>
            <div className="mt-6">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={(e) => handleSignInClick(e)}
              >
                Get Started
              </button>
              <button
                className="ml-4 bg-transparent border border-white hover:bg-white hover:text-black text-white font-bold py-2 px-4 rounded"
                onClick={() => navigate("/education")}
              >
                Learn More
              </button>
            </div>
          </div>
        </section>

        <FeaturedTools />
        <Testimonials />
      </main>
    </div>
  );
};

export default Home;
