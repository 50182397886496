import React, { useState, useRef, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi"; // Icons for burger and close
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { useUser } from "../context/UserContext";

const DashboardLayout: React.FC = () => {
  const { user, setUser } = useUser();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleSignOut = () => {
    // Clear the token and any other user-related data from localStorage
    localStorage.removeItem("token");
    setUser({});
    // Redirect to the login page
    navigate("/login");
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const isActive = (path: string) =>
    location.pathname === path
      ? "text-blue-500 font-bold py-2 px-4 flex items-center rounded "
      : "hover:bg-gray-700 py-2 px-4 flex items-center rounded";

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      {/* Sidebar */}
      {/* removed className="flex overflow-hidden"  from above div */}

      <aside
        ref={sidebarRef}
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 fixed top-0 left-0 w-60 bg-gray-800 text-white h-full transition-transform duration-300 ease-in-out z-40`}
      >
        <div className="h-15">
          <div className="flex justify-between items-center px-6 py-4 md:hidden">
            {/* <span className="text-2xl font-bold">Dashboard</span> */}
            <button onClick={toggleSidebar}>
              <FiX size={30} className="text-white" />
            </button>
          </div>
        </div>

        {/* <div className="px-6 py-4">
          <h2 className="text-2xl font-bold hidden md:block">Dashboard</h2>
        </div> */}
        <div className="flex flex-col h-[90%] justify-between">
          <nav className="px-4">
            <ul>
              <li className="mb-2">
                <Link to="/" className={isActive("/")} onClick={toggleSidebar}>
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/my-tools"
                  className={isActive("/my-tools")}
                  onClick={toggleSidebar}
                >
                  My Tools
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  onClick={toggleSidebar}
                  to="/subscription-management"
                  className={isActive("/subscription-management")}
                >
                  Subscription Management
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/referrals"
                  className={isActive("/referrals")}
                  onClick={toggleSidebar}
                >
                  Referral Program
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/support"
                  className={isActive("/support")}
                  onClick={toggleSidebar}
                >
                  Support & Help
                </Link>
              </li>
            </ul>
          </nav>

          <div className=" px-4 py-6">
            <Link to="/profile">
              <div className="flex  space-x-2">
                <img
                  src="/assets/images/userwhite.png"
                  alt="Profile"
                  className="rounded-full w-10 h-10"
                />
                <span className="capitalize pt-2">
                  {user["name"] || "User"}
                </span>
              </div>
            </Link>
            <button
              onClick={handleSignOut}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-3"
            >
              Sign Out
            </button>
          </div>
        </div>
      </aside>
      <div>
        {/* Header for mobile */}
        {!isSidebarOpen && (
          <header className="bg-gray-800 text-white p-4 md:hidden flex justify-between items-center fixed top-0 left-0 w-full">
            <button onClick={toggleSidebar}>
              <FiMenu size={30} />
            </button>
            {/* <div className="text-xl font-bold">Dashboard</div> */}
            <Link to="/profile">
              <div className="flex items-center space-x-2">
                <span className="capitalize">{user["name"] || "User"}</span>
                <img
                  src="/assets/images/userwhite.png"
                  alt="Profile"
                  className="rounded-full w-8 h-8"
                />
              </div>
            </Link>
          </header>
        )}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
