// src/components/FeaturedTools.tsx
import React from "react";

const FeaturedTools: React.FC = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">Our Tools</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 shadow-lg rounded-lg">
            <h3 className="text-xl font-bold mb-4">App Clones (Simulated)</h3>
            <p>
              A tool that replicates the interface of various types of apps,
              demonstrating how attackers can use fake apps to mimic legitimate
              platforms for social engineering purposes.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg">
            <h3 className="text-xl font-bold mb-4">Email Phishing Simulator</h3>
            <p>
              A phishing tool that helps you understand how attackers trick
              users into giving up sensitive information.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg">
            <h3 className="text-xl font-bold mb-4">
              Awareness Training Module
            </h3>
            <p>
              Interactive learning tools for businesses to train their employees
              on social engineering risks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedTools;
