import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const Referral: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleGoToHomepage = () => {
    navigate("/"); // Redirect to the homepage
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {" "}
      {/* Centering the content */}
      <div className="text-center">
        <p>
          <i className="fa-solid fa-gear fa-spin  fa-2xl"></i>{" "}
          <i className="fa-solid fa-gear fa-spin fa-spin-reverse fa-2xl"></i>
        </p>
        <h1 className="text-2xl font-bold mb-4">Under Development</h1>
        <button
          onClick={handleGoToHomepage}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700" // Button styles
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};
export default Referral;
