import React from "react";

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
      <p>
        Welcome to SocEngAwareness. By accessing or using our website, tools, or
        services, you agree to comply with the following terms. If you do not
        agree with these terms, you are prohibited from using our tools or
        services.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        1. Educational and Research Purposes Only
      </h2>
      <p>
        The tools, resources, and content provided on SocEngAwareness, including
        but not limited to the App clones and other simulation tools, are
        strictly intended for{" "}
        <strong>
          educational, research, and ethical security training purposes only
        </strong>
        . These tools are designed to help users understand and analyze
        potential vulnerabilities in a controlled, responsible, and lawful
        manner.
      </p>
      <p>
        <strong>Prohibited Uses</strong>: Misuse of these tools for illegal,
        unethical, or malicious purposes, such as hacking, fraud, or identity
        theft, is strictly prohibited. Engaging in such activities may result in
        legal action against you, as well as immediate termination of access to
        our services.
      </p>

      <h2 className="text-xl font-semibold mt-4">2. User Responsibility</h2>
      <p>
        By using SocEngAwareness tools, you affirm that you will use them solely
        for lawful and ethical purposes. You acknowledge that it is your
        responsibility to ensure that your use complies with all applicable
        laws, regulations, and ethical standards in your jurisdiction.
      </p>
      <p>
        <strong>Examples of Prohibited Activities</strong> include but are not
        limited to:
      </p>
      <ul className="list-disc ml-6">
        <li>
          Attempting to exploit or manipulate the tools for financial gain or
          other illegal purposes.
        </li>
        <li>
          Using the tools to harm, defraud, or compromise the security of
          individuals, companies, or networks.
        </li>
        <li>
          Sharing or distributing the tools for purposes that contradict their
          intended use.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">3. Limitation of Liability</h2>
      <p>
        SocEngAwareness provides these tools <strong>"as is"</strong>, and we do
        not make any warranties or representations about the accuracy or
        completeness of the tools or their results.{" "}
        <strong>
          SocEngAwareness is not liable for any direct, indirect, incidental, or
          consequential damages resulting from the use or misuse of our tools.
        </strong>
      </p>
      <p>We disclaim any responsibility for:</p>
      <ul className="list-disc ml-6">
        <li>Misuse of the tools for illegal or malicious activities.</li>
        <li>Unauthorized access or alteration of user data.</li>
        <li>Any consequences arising from third-party use of the tools.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">
        4. No Legal or Professional Advice
      </h2>
      <p>
        The tools provided by SocEngAwareness are{" "}
        <strong>
          not intended to provide legal, financial, or professional advice
        </strong>
        . They are offered as educational resources only. Users should consult
        qualified professionals before using the tools in any real-world
        applications or security audits.
      </p>

      <h2 className="text-xl font-semibold mt-4">5. Indemnification</h2>
      <p>
        By using our services, you agree to{" "}
        <strong>
          indemnify, defend, and hold SocEngAwareness, its affiliates,
          employees, and partners harmless
        </strong>{" "}
        from any claims, liabilities, damages, or expenses arising from your use
        or misuse of the tools. This includes, but is not limited to, any breach
        of these Terms of Use or violations of laws.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        6. Subscription and Payment Terms
      </h2>
      <p>
        Some tools on SocEngAwareness may require payments or subscriptions for
        access. All payments are processed securely through Paystack or other
        approved payment processors. By subscribing or making a payment, you
        agree that:
      </p>
      <ul className="list-disc ml-6">
        <li>All payments are non-refundable.</li>
        <li>
          You are responsible for ensuring the accuracy of the subscription or
          purchase.
        </li>
        <li>
          Misuse of payment references or attempts to bypass payment for tools
          is strictly prohibited.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">
        7. Intellectual Property Rights
      </h2>
      <p>
        All content, tools, software, and materials available on SocEngAwareness
        are the intellectual property of SocEngAwareness. You are granted a
        limited license to use the tools strictly for educational purposes.{" "}
        <strong>
          Unauthorized distribution, modification, or commercial use
        </strong>{" "}
        of these materials without permission is prohibited and may result in
        legal action.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        8. Disclaimer of Warranties
      </h2>
      <p>
        SocEngAwareness makes no warranties, express or implied, regarding the
        tools or services provided. While we strive to ensure that the tools
        function as intended, we cannot guarantee their accuracy, reliability,
        or suitability for any specific purpose.
      </p>
      <p>
        You understand that{" "}
        <strong>using the tools may expose you to potential risks</strong>, and
        you assume full responsibility for any consequences that may arise from
        your use.
      </p>

      <h2 className="text-xl font-semibold mt-4">9. Termination of Use</h2>
      <p>
        We reserve the right to suspend or terminate your access to our tools
        and services at any time without prior notice if we believe you have
        violated these Terms of Use or engaged in unethical or illegal behavior.
      </p>

      <h2 className="text-xl font-semibold mt-4">10. Legal Compliance</h2>
      <p>
        You are responsible for ensuring that your use of the tools complies
        with the laws and regulations applicable to your country or region.
        SocEngAwareness is not responsible for any illegal activities conducted
        using our tools, and we will cooperate fully with authorities in the
        event of any investigations or legal inquiries.
      </p>

      <h2 className="text-xl font-semibold mt-4">11. Acknowledgment</h2>
      <p>By using SocEngAwareness tools, you acknowledge and agree that:</p>
      <ul className="list-disc ml-6">
        <li>
          These tools are for <strong>educational and ethical use only</strong>.
        </li>
        <li>
          Any misuse of the tools will result in consequences, including but not
          limited to suspension of your access and potential legal action.
        </li>
        <li>
          SocEngAwareness is not responsible for any harm, damage, or losses
          caused by your use or misuse of the tools.
        </li>
      </ul>
      <p>
        If you disagree with any part of these terms or are not willing to abide
        by them, you are advised to immediately cease using SocEngAwareness and
        its services.
      </p>
    </div>
  );
};

export default TermsOfService;
