import React, { useState } from "react";

interface ActionButtonProps {
  name: string; // Button label
  className?: string; // Custom styles or Tailwind classes
  onClick?: () => Promise<void> | void; // Optional function to call on click
  disabled?: boolean; // Optional prop to initially disable the button
}

const ActionButton: React.FC<ActionButtonProps> = ({
  name,
  className = "",
  onClick,
  disabled,
}) => {
  const [isDisabled, setIsDisabled] = useState(disabled); // Set initial state based on the optional disabled prop

  const handleClick = async () => {
    if (isDisabled || !onClick) return; // Prevent click if disabled or if no onClick handler is provided

    setIsDisabled(true); // Disable button when clicked
    try {
      await onClick(); // Execute the passed function (if provided)
    } catch (err) {
      console.error("Action failed:", err);
    } finally {
      setIsDisabled(false); // Enable the button again after action is complete
    }
  };

  return (
    <button
      className={`${className} ${
        isDisabled ? "opacity-70 cursor-not-allowed" : ""
      }`} // Add styles for disabled state
      onClick={handleClick}
      disabled={isDisabled}
    >
      {name}
    </button>
  );
};

export default ActionButton;
