import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";

// Define a User type as a flexible structure (open-ended object type)
interface User {
  [key: string]: any; // Allows the user object to have any key-value pairs
}

// Create a context with an initial empty object as the default value
interface UserContextType {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  loading: boolean;
  isAuthenticated: boolean;
  refetchUserData: () => Promise<void>; // Add refetch function
}

const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a custom hook to access the context
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

// Provider component to wrap the app and provide user data
interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = React.memo(
  ({ children }) => {
    const [user, setUser] = useState<User>({});
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    const fetchUserData = async () => {
      const token = localStorage.getItem("token");

      if (token && Object.keys(user).length === 0) {
        try {
          console.log("fetching user data...");
          const response = await fetch(
            "https://api.socengawareness.org/get-user",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "ngrok-skip-browser-warning": "true",
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            setUser(data);
            setIsAuthenticated(true);
          } else {
            localStorage.removeItem("token");
            setIsAuthenticated(false);
            navigate("/login");
          }
        } catch (error: any) {
          console.error("Failed to fetch user:", error.message);
          setIsAuthenticated(false);
          navigate("/login");
        }
      } else if (!token) {
        navigate("/login");
      }

      setLoading(false);
    };

    // Debounce the fetchUserData function
    const debouncedFetchUserData = debounce(fetchUserData, 300);

    useEffect(() => {
      debouncedFetchUserData();

      // Cleanup: cancel any pending debounced calls
      return () => {
        debouncedFetchUserData.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Function to refetch user data
    const refetchUserData = async () => {
      setLoading(true); // Optionally set loading state
      await fetchUserData();
    };

    // Memoize the value to avoid re-creating the object on every render
    const contextValue = useMemo(
      () => ({
        user,
        setUser,
        loading,
        isAuthenticated,
        refetchUserData, // Expose the refetch function
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [user, setUser, loading, isAuthenticated]
    );

    return (
      <UserContext.Provider value={contextValue}>
        {!loading && children}
      </UserContext.Provider>
    );
  }
);
