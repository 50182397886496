import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> September 25th 2024
      </p>
      <p>
        At SocEngAwareness, your privacy is important to us. This Privacy Policy
        outlines the types of information we collect, how we use it, and the
        steps we take to protect your personal information. By using our
        website, tools, or services, you agree to the practices described in
        this policy.
      </p>

      <h2 className="text-xl font-semibold mt-4">1. Information We Collect</h2>
      <p>
        We collect personal information that you voluntarily provide to us when
        registering on our platform, subscribing to our services, or using our
        tools. This includes, but is not limited to:
      </p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Personal Identification Information:</strong> Name, email
          address, and other contact details.
        </li>
        <li>
          <strong>Login Information:</strong> User login IDs, device IDs (for
          specific tools such as CashApp clone), and subscription details.
        </li>
        <li>
          <strong>Payment Information:</strong> Payment references, transaction
          details, and billing information for subscriptions or purchases.
        </li>
      </ul>
      <p>We may also collect non-personal information, such as:</p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Usage Data:</strong> Information about how you use our tools,
          including your IP address, browser type, and activity logs.
        </li>
        <li>
          <strong>Technical Data:</strong> Information regarding the devices you
          use to access our website (e.g., device model, operating system).
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">
        2. How We Use Your Information
      </h2>
      <p>We use the information we collect to:</p>
      <ul className="list-disc ml-6">
        <li>Provide, operate, and maintain our services.</li>
        <li>Process your subscriptions, purchases, and payments securely.</li>
        <li>
          Enhance user experience by tailoring content and features based on
          user preferences.
        </li>
        <li>
          Notify you about updates, promotions, or new tools if you opt-in to
          receive communications.
        </li>
        <li>
          Improve the security and functionality of our tools, including
          conducting security analysis.
        </li>
        <li>
          Ensure compliance with applicable laws, regulations, and terms of use.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">3. Data Security</h2>
      <p>
        We are committed to protecting your personal information. We implement
        robust security measures to safeguard your data against unauthorized
        access, alteration, disclosure, or destruction. These measures include:
      </p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Encryption:</strong> We use encryption protocols to secure
          sensitive data such as payment information.
        </li>
        <li>
          <strong>Access Controls:</strong> Only authorized personnel have
          access to personal data, and we enforce strict access controls.
        </li>
        <li>
          <strong>Regular Monitoring:</strong> We monitor for potential security
          breaches and take appropriate actions to address them.
        </li>
      </ul>
      <p>
        Despite our best efforts, no system can guarantee complete security. We
        cannot ensure or warrant the security of any information you transmit to
        us, and you do so at your own risk.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        4. Third-Party Service Providers
      </h2>
      <p>
        We use third-party services for payment processing, data hosting, and
        analytics. These third-party providers have access to your personal
        information only to the extent necessary to perform their functions and
        are obligated not to disclose or use it for any other purposes.
      </p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Payment Processors:</strong> We use Paystack to handle
          payments securely. We do not store your full payment card details on
          our servers. Paystack has its own privacy policies governing the
          information they collect.
        </li>
        <li>
          <strong>Analytics Providers:</strong> We may use third-party analytics
          services to analyze how our tools are used to improve functionality
          and user experience.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">
        5. Cookies and Tracking Technologies
      </h2>
      <p>
        Our website uses cookies and similar tracking technologies to enhance
        your experience. Cookies are small files stored on your device that help
        us understand user behavior, save your preferences, and provide
        personalized content.
      </p>
      <p>
        You may choose to disable cookies through your browser settings, but
        this may affect your ability to use some features of our website.
      </p>

      <h2 className="text-xl font-semibold mt-4">6. Data Retention</h2>
      <p>
        We retain personal information for as long as it is necessary to provide
        you with our services, comply with legal obligations, resolve disputes,
        and enforce our agreements. Once the information is no longer needed, we
        will securely delete or anonymize it.
      </p>

      <h2 className="text-xl font-semibold mt-4">7. Your Rights</h2>
      <p>You have the following rights concerning your personal data:</p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Access:</strong> You can request a copy of the information we
          hold about you.
        </li>
        <li>
          <strong>Correction:</strong> You can request that we update or correct
          any inaccuracies in your personal information.
        </li>
        <li>
          <strong>Deletion:</strong> You can request the deletion of your data
          under certain circumstances, such as when it is no longer needed for
          the purpose it was collected.
        </li>
        <li>
          <strong>Withdrawal of Consent:</strong> If you previously consented to
          the collection of your data, you can withdraw that consent at any
          time.
        </li>
      </ul>
      <p>
        To exercise any of these rights, please contact us at
        support@socengawareness.org. We will respond to your request within a
        reasonable timeframe.
      </p>

      <h2 className="text-xl font-semibold mt-4">8. Children's Privacy</h2>
      <p>
        SocEngAwareness is not intended for use by individuals under the age of
        18. We do not knowingly collect or solicit personal information from
        children. If we become aware that we have inadvertently collected
        personal information from a child under 18, we will delete it as soon as
        possible.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        9. International Data Transfers
      </h2>
      <p>
        Your information may be transferred to, stored, and processed in
        countries other than your own, where data protection laws may differ. By
        using our services, you consent to the transfer of your information to
        countries outside of your jurisdiction.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        10. Limitation of Liability
      </h2>
      <p>
        SocEngAwareness is not responsible for any loss, damage, or harm
        resulting from the unauthorized access or misuse of your personal
        information unless it is caused by our negligence or intentional
        wrongdoing. By using our services, you acknowledge that no system is
        entirely foolproof and that you are using the services at your own risk.
      </p>

      <h2 className="text-xl font-semibold mt-4">
        11. Changes to this Privacy Policy
      </h2>
      <p>
        We reserve the right to update this Privacy Policy at any time. Any
        changes will be posted on this page, and we will update the "Effective
        Date" at the top. Your continued use of our website and services after
        any modifications indicates your acceptance of the revised policy.
      </p>

      <h2 className="text-xl font-semibold mt-4">12. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, or if
        you wish to exercise your rights, please contact us at:
      </p>
      <ul className="list-disc ml-6">
        <li>
          <strong>Email:</strong> support@socengawareness.org
        </li>
      </ul>
      <p>
        We are committed to resolving any concerns you may have regarding your
        privacy and data security.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
