import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // Step 1: email verification, Step 2: enter code, Step 3: complete registration
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [reason, setReason] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [verificationCode, setVerificationCode] = useState(""); // Store verification code
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      // const response = await fetch(
      //   "https://api.socengawareness.org/send-code",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ email: email }),
      //   }
      // );

      // if (!response.ok) {
      //   const errorResponse = await response.json();
      //   throw new Error(
      //     errorResponse.detail || "Failed to send verification code"
      //   );
      // }

      setStep(3); // Move to next step: enter verification code
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");

      const response = await fetch(
        "https://api.socengawareness.org/verify-code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, code: verificationCode }),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.detail || "Invalid verification code");
      }

      setStep(3); // Move to next step: complete registration
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRegistrationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    const userData = {
      name,
      email,
      password,
      confirm_password: confirmPassword,
      role,
      reason,
      extra_info: extraInfo,
    };

    try {
      setLoading(true);
      setError("");
      const response = await fetch("https://api.socengawareness.org/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.detail || "An error occurred");
      }

      const data = await response.json();
      localStorage.setItem("token", data.access_token);
      navigate("/");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen py-12 bg-gray-800 flex justify-center items-center">
      <div className="bg-white p-8 shadow-lg rounded-2xl w-full max-w-lg mx-5">
        <h2 className="text-2xl font-bold mb-3 font-poppins">
          Create your account
        </h2>
        <p className="font-bold pb-3">
          Have an account?
          <span>
            <Link to={"/login"} className="text-blue-500 hover:underline px-1">
              Log in now
            </Link>
          </span>
        </p>

        {error && (
          <div className="bg-red-100 text-red-700 p-4 mb-6 rounded">
            {error}
          </div>
        )}

        {step === 1 && (
          <form onSubmit={handleEmailSubmit} className="space-y-4">
            {/* Email Input */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "loading..." : "Next"}
              </button>
            </div>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleCodeSubmit} className="space-y-4">
            {/* Verification Code Input */}
            <div>
              <label
                htmlFor="verificationCode"
                className="block text-gray-700 font-semibold"
              >
                Enter Verification Code
              </label>
              <input
                type="text"
                id="verificationCode"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </div>

            {/* Inform the user to check spam/junk folder */}
            <p className="text-sm text-gray-500">
              Can't find the code? Be sure to check your spam or junk folder.
            </p>

            {/* Resend Code Button */}
            <div className="text-center">
              <button
                type="button"
                onClick={() => setStep(1)} // Take user back to step 1 to resend the code
                className="text-blue-500 hover:underline mb-4"
              >
                Resend Code
              </button>
            </div>

            {/* Submit Button to Verify the Code */}
            <div className="text-center">
              <button
                type="submit"
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Verifying..." : "Verify Code"}
              </button>
            </div>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handleRegistrationSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={email}
                required
                readOnly
              />
            </div>
            {/* Name Input */}
            <div>
              <label
                htmlFor="name"
                className="block text-gray-700 font-semibold"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            {/* Password Input */}
            <div>
              <label
                htmlFor="password"
                className="block text-gray-700 font-semibold"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            {/* Confirm Password Input */}
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-gray-700 font-semibold"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {/* Role Selection */}
            <div>
              <label
                htmlFor="role"
                className="block text-gray-700 font-semibold"
              >
                What best describes you?
              </label>
              <select
                id="role"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option value="">Select your role</option>
                <option value="student">Student</option>
                <option value="professional">Professional</option>
                <option value="educator">Educator</option>
                <option value="researcher">Researcher</option>
                <option value="other">Other</option>
              </select>
            </div>

            {role === "student" && (
              <div>
                <label
                  htmlFor="institution"
                  className="block text-gray-700 font-semibold"
                >
                  Institution
                </label>
                <input
                  type="text"
                  id="institution"
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                  placeholder="Enter your school or university"
                  required
                />
              </div>
            )}
            {role === "professional" && (
              <div>
                <label
                  htmlFor="organization"
                  className="block text-gray-700 font-semibold"
                >
                  Organization
                </label>
                <input
                  type="text"
                  id="organization"
                  className="w-full p-2 border border-gray-300 rounded mt-1"
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                  placeholder="Enter your company name"
                  required
                />
              </div>
            )}
            {/* Reason for Signing Up */}
            <div>
              <label
                htmlFor="reason"
                className="block text-gray-700 font-semibold"
              >
                Why are you signing up?
              </label>
              <select
                id="reason"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              >
                <option value="">Select your reason</option>
                <option value="learnAboutSocialEngineering">
                  To learn more about social engineering
                </option>
                <option value="improveSecuritySkills">
                  To improve my security skills
                </option>
                <option value="corporateTraining">
                  For corporate or staff training
                </option>
                <option value="research">For research purposes</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              <label htmlFor="terms-checkbox" className="text-sm">
                I accept the{" "}
                <a href="/privacy-policy" className="text-blue-500 underline">
                  Privacy Policy
                </a>{" "}
                and the{" "}
                <a href="/terms-of-service" className="text-blue-500 underline">
                  Terms of Service
                </a>
              </label>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                  loading || !isChecked ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading || !isChecked}
              >
                {loading ? "Signing Up..." : "Sign Up"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignUp;
