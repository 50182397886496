import React from "react";
import { useUser } from "../context/UserContext"; // Assuming you have a context for user info
import { useNavigate } from "react-router-dom";

const Profile: React.FC = () => {
  const { user, setUser } = useUser(); // Access user data
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser({}); // Clear user data
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="flex flex-wrap p-8">
      <div className="hidden sm:block md:w-64"></div>

      <div className="w-full max-w-md mx-auto mt-10 md:mt-2">
        <div className="mb-4 ">
          <img
            src="/assets/images/user.png"
            alt="Profile"
            className="rounded-full w-20 h-20 mx-auto"
          />
          <p className="capitalize text-center">{user.name || "N/A"}</p>
        </div>

        <div className="mb-4">
          <p className="text-center">{user.email || "N/A"}</p>
        </div>

        <button
          onClick={handleLogout}
          className="bg-red-500 text-white py-2 px-4 rounded w-full"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Profile;
