import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { useAuth } from "../context/AuthContext"; // Import the auth context

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated, signOut } = useAuth(); // Get auth state and signOut function
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleSignInClick = (e: any) => {
    e.preventDefault();
    const currentHost = window.location.host; // Gets the current host (e.g., localhost:3000)

    // Check if it's already a dashboard subdomain, if not, append it
    if (!currentHost.startsWith("dashboard")) {
      const newHost = `dashboard.${currentHost}`;
      window.location.href = `http://${newHost}/login`; // Redirect to the subdomain with /login
    }
  };

  const handleSignOut = () => {
    signOut(); // Call the signOut function from the context
  };

  // Function to apply "active" class if the current path matches the link's path
  const isActive = (path: string) =>
    location.pathname === path
      ? "text-blue-500 font-bold"
      : "hover:text-gray-400";

  // Close the menu on route change
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  // Close the menu when the user scrolls
  useEffect(() => {
    const handleScroll = () => {
      setMenuOpen(false);
    };

    if (menuOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuOpen]);
  return (
    <header className="bg-gray-800 text-white fixed top-0 left-0 w-full">
      <nav className="container mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <div className="text-lg font-bold">
          <Link to="/">SocEngAwareness</Link>
        </div>

        {/* Hamburger Menu for mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Desktop Menu Items */}
        <ul className="hidden md:flex space-x-4 md:space-x-8">
          <li>
            <Link to="/" className={isActive("/")}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/tools" className={isActive("/tools")}>
              Tools
            </Link>
          </li>
          <li>
            <Link to="/education" className={isActive("/education")}>
              Education
            </Link>
          </li>
          <li>
            <Link to="/contact" className={isActive("/contact")}>
              Contact
            </Link>
          </li>
        </ul>

        {/* Profile Section or Sign In/Sign Up */}
        <div className="hidden md:flex items-center space-x-4">
          {!isAuthenticated ? (
            <>
              <button
                onClick={handleSignInClick}
                className="text-white hover:text-gray-400"
              >
                Sign In
              </button>
              <button
                onClick={handleSignInClick}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Sign Up
              </button>
            </>
          ) : (
            <div className="flex items-center space-x-4">
              <img
                src="https://via.placeholder.com/40"
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <span className="font-bold">Welcome, User</span>
              <button
                onClick={handleSignOut}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </nav>

      {/* Responsive Menu for mobile (only visible when menuOpen is true) */}
      <div className={`md:hidden ${menuOpen ? "block" : "hidden"}`}>
        <ul className="space-y-4 p-4 bg-gray-800 text-white">
          <li>
            <Link to="/" className={isActive("/")} onClick={toggleMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/tools"
              className={isActive("/tools")}
              onClick={toggleMenu}
            >
              Tools
            </Link>
          </li>
          <li>
            <Link
              to="/education"
              className={isActive("/education")}
              onClick={toggleMenu}
            >
              Education
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={isActive("/contact")}
              onClick={toggleMenu}
            >
              Contact
            </Link>
          </li>
          {!isAuthenticated ? (
            <>
              <li>
                <button
                  className="hover:text-gray-400"
                  onClick={handleSignInClick}
                >
                  Sign In
                </button>
              </li>
              <li>
                <button
                  onClick={handleSignInClick}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Sign Up
                </button>
              </li>
            </>
          ) : (
            <li>
              <div className="flex items-center space-x-4">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Profile"
                  className="w-10 h-10 rounded-full"
                />
                <span className="font-bold">Welcome, User</span>
                <button
                  onClick={handleSignOut}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Sign Out
                </button>
              </div>
            </li>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;
