import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";

// Define the Tool type
interface Tool {
  _id: string;
  name: string;
  description: string;
  price: number;
  isavailable: boolean;
}

// Define the context type
interface ToolsContextType {
  tools: Tool[];
  loading: boolean;
  error: string | null;
}

// Create the context with an initial empty state
const ToolsContext = createContext<ToolsContextType | undefined>(undefined);

// Create a custom hook to access the context
export const useTools = () => {
  const context = useContext(ToolsContext);
  if (!context) {
    throw new Error("useTools must be used within a ToolsProvider");
  }
  return context;
};

// Provider component to fetch tools once and provide them to the app
interface ToolsProviderProps {
  children: ReactNode;
}

export const ToolsProvider: React.FC<ToolsProviderProps> = ({ children }) => {
  const [tools, setTools] = useState<Tool[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("redirectTo", location.pathname);
    console.log(location.pathname);
    const fetchTools = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          "https://api.socengawareness.org/get-tools",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch tools");
        }

        const data = await response.json();
        setTools(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load tools. Please try again later.");
        setLoading(false);
      }
    };

    // Debounce the fetchTools function to limit how frequently it is called
    const debouncedFetchTools = debounce(fetchTools, 300);

    debouncedFetchTools();

    // Cleanup function to cancel pending debounce calls
    return () => {
      debouncedFetchTools.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use `useMemo` to avoid re-creating the `value` object unless `tools`, `loading`, or `error` change
  const value = useMemo(
    () => ({ tools, loading, error }),
    [tools, loading, error]
  );

  return (
    <ToolsContext.Provider value={value}>{children}</ToolsContext.Provider>
  );
};
