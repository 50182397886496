// src/components/Testimonials.tsx
import React from "react";

const testimonials = [
  {
    quote:
      "SocEngAwareness tools helped our team understand the importance of social engineering defenses.",
    author: "John, Security Officer",
  },
  {
    quote:
      "The CashApp clone tool was an eye-opener for our staff in detecting phishing scams.",
    author: "Smith, Business Owner",
  },
];

const Testimonials: React.FC = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto">
        {/* Heading */}
        <h2 className="text-4xl font-bold mb-6 text-center">
          What Our Clients Say
        </h2>

        {/* Testimonials Wrapper */}
        <div className="overflow-hidden relative">
          <div className="animate-slide flex space-x-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="min-w-full ">
                <p className="text-xl italic">"{testimonial.quote}"</p>
                <p className="mt-2 text-lg font-semibold text-center">
                  - {testimonial.author}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
