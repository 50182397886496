import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext"; // Assuming you have a context to fetch user info
import { useTools } from "../context/ToolsContext"; // Assuming you have a context to fetch user info
import Notification from "../components/Notification"; // Import notification component
import ActionButton from "../components/ActionButton";
import LoadingSpinner from "../components/LoadingSpinner";

const DashboardHome: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser(); // Get user info from the context
  const { tools } = useTools(); // Get user info from the context
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const [loading, setLoading] = useState(false);

  // Function to check if a tool is already purchased
  const isToolOwned = (toolId: string): boolean => {
    console.log(user);
    return user?.purchased_tools?.some(
      (purchase: any) => purchase.tool_id === toolId
    );
  };

  // Handle purchasing a tool
  const handlePurchase = async (toolId: string) => {
    const token = localStorage.getItem("token");

    if (!navigator.onLine) {
      setNotification({ message: "No internet connection.", type: "error" });
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `https://api.socengawareness.org/purchase/${toolId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        setLoading(false);

        if (response.status >= 500) {
          throw new Error("Server error");
        }
        throw new Error("Failed to purchase tool.");
      }

      const data = await response.json();
      setNotification({ message: data.message, type: "success" });
      navigate(0);
    } catch (err: any) {
      setLoading(false);

      setNotification({
        message:
          err.message === "Server error"
            ? "Server is down. Try again later."
            : err.message,
        type: "error",
      });
    }
  };

  // Separate available and unavailable tools
  const availableTools = tools.filter((tool) => tool.isavailable);
  const unavailableTools = tools.filter((tool) => !tool.isavailable);

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="hidden sm:block md:w-64"></div>

        <div className="px-5 flex-1 mt-10 pt-10 md:mt-10 md:pt-0">
          {loading && <LoadingSpinner message={""} />}
          <div>
            <h1 className="text-3xl font-bold mb-4 text-center">
              Available Tools
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 ">
              {availableTools.map((tool) => (
                <div
                  key={tool._id}
                  className="bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between w-full mx-auto "
                >
                  <div>
                    <h2 className="text-xl font-bold mb-2">{tool.name}</h2>
                    <p className="text-gray-600 mb-4">{tool.description}</p>
                  </div>
                  <div>
                    {isToolOwned(tool._id) ? (
                      <div className="flex flex-row  w-full">
                        <ActionButton
                          className="bg-gray-400 text-white py-2 px-6  rounded"
                          disabled={true}
                          name="Owned"
                        />
                        <Link
                          className="bg-green-500 text-white py-2 px-2  rounded ms-9"
                          to={`/my-tools/${tool._id}/access`}
                        >
                          Access Tool
                        </Link>
                      </div>
                    ) : tool.price < 1 ? (
                      <ActionButton
                        className="bg-green-500 text-white py-2 px-4 rounded"
                        onClick={() => handlePurchase(tool._id)}
                        disabled={false}
                        name="Add to My Tools"
                      />
                    ) : (
                      <Link to={`/tools/${tool._id}/purchase`}>
                        <ActionButton
                          className="bg-blue-500 text-white py-2 px-4 rounded"
                          name={`Buy Now - $${tool.price.toFixed(2)}`}
                          disabled={false}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Section for unavailable tools */}
            {unavailableTools.length > 0 && (
              <div className="mt-8">
                <h1 className="text-3xl font-bold mb-4 text-center">
                  Tools Coming Soon
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6">
                  {unavailableTools.map((tool) => (
                    <div
                      key={tool._id}
                      className="bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between w-full mx-auto items-center"
                    >
                      <div>
                        <h2 className="text-xl font-bold mb-2">{tool.name}</h2>
                        <p className="text-gray-600 mb-4">{tool.description}</p>
                      </div>
                      <div>
                        <ActionButton
                          className="bg-yellow-500 text-white py-2 px-4 rounded"
                          disabled={true}
                          name="Coming Soon"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Notification component */}
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)} // Hide after the notification disappears
        />
      )}
    </div>
  );
};

export default DashboardHome;
