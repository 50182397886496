import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <p>
          © 2024 SocEngAwareness |{" "}
          <a href="/" className="hover:text-gray-400">
            Privacy Policy
          </a>{" "}
          |{" "}
          <a href="/#" className="hover:text-gray-400">
            Terms of Service
          </a>
        </p>
        <p className="mt-4">
          Need help? Contact us at{" "}
          <a
            href="mailto:support@socengawareness.com"
            className="hover:text-gray-400"
          >
            support@socengawareness.org
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
