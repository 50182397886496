import React, { useState, useEffect } from "react";
import ActionButton from "../components/ActionButton";
import LoadingSpinner from "../components/LoadingSpinner";
import Notification from "../components/Notification"; // For displaying messages
import PaystackPop from "@paystack/inline-js"; // Paystack inline SDK for payment
import { useUser } from "../context/UserContext";

// Custom hook to handle Paystack postMessage events
const usePaystackEventHandler = (
  handlePaymentClosed: any,
  handlePaymentSuccess: any
) => {
  useEffect(() => {
    const handlePaystackMessage = (event: any) => {
      if (!event.origin.includes("paystack.co")) return;

      const paystackEventTypes = {
        close: "close",
        success: "success",
      };

      const { event: eventType } = event.data;

      if (eventType === paystackEventTypes.close) {
        handlePaymentClosed();
      }

      if (eventType === paystackEventTypes.success) {
        handlePaymentSuccess();
      }
    };

    window.addEventListener("message", handlePaystackMessage);
    return () => {
      window.removeEventListener("message", handlePaystackMessage);
    };
  }, [handlePaymentClosed, handlePaymentSuccess]);
};

const Subscription: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>("Weekly"); // Default selection
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("Loading...");
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "error";
  } | null>(null);
  const { user, setUser, refetchUserData } = useUser();
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [subscribingNumber, setSubscribingNumber] = useState<string>("");
  const [paymentRef, setPaymentRef] = useState<string>("");

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      // Extract the subscriptions from user data
      if (user && user.subscriptions && user.subscriptions.length > 0) {
        setSubscriptions(user.subscriptions); // Set subscriptions array
      }
    }
  }, [user]);
  // Handle successful payment verification
  const handlePaymentSuccess = async () => {
    handleSubscribe(selectedPlan, subscribingNumber, paymentRef);
  };

  // Handle payment popup close
  const handlePaymentClosed = () => {
    setNotification({ message: "Payment was cancelled.", type: "error" });
    setIsLoading(false);
  };

  // Use the Paystack event handler hook
  usePaystackEventHandler(handlePaymentClosed, handlePaymentSuccess);

  // Handle subscription payment
  const handleSubscribe = async (
    plan: string,
    login_number: string,
    reference: string
  ) => {
    setIsLoading(true);
    setLoadingText("Subscribing");
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://api.socengawareness.org/subscribe",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plan_type: plan,
            login_number: login_number,
            payment_reference: reference,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      setNotification({
        message: "Subscription successful!",
        type: "success",
      });
      console.log(data);
      setUser({});
      await refetchUserData();

      // const reloadUrl = window.location.href;
      // window.location.href = reloadUrl;
      setIsLoading(false);
    } catch (error) {
      setNotification({
        message: "Subscription failed.",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  const initializeSubscription = async (plan: string, number: string) => {
    setIsLoading(true);
    setLoadingText("Initializing payment...");
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://api.socengawareness.org/paystack/subscription/initialize`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscription_type: plan,
            login_number: number,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      setSubscribingNumber(number);
      const popup = new PaystackPop();
      setPaymentRef(data.data.reference);
      popup.resumeTransaction(data.data.access_code);
    } catch (error) {
      setIsLoading(false);
      setNotification({
        message: "Payment initialization failed.",
        type: "error",
      });
    }
  };
  if (Object.keys(user).length !== 0) {
    return (
      <div className="flex flex-wrap">
        <div className="hidden sm:block md:w-64"></div>
        <div className="px-5 flex-1 mt-10 pt-10 md:mt-10 md:pt-0">
          <h1 className="text-3xl font-bold mb-6">Subscription Management</h1>
          {isLoading && <LoadingSpinner message={loadingText} />}

          {user.login_numbers.length > 0 ? (
            user.login_numbers.map((login_number: string, index: number) => {
              const subscription = subscriptions.find(
                (sub) => sub.login_number === login_number
              );

              // If a subscription is found for the login number, display it
              if (subscription) {
                const {
                  plan_type,
                  subscription_end_date,
                  subscription_status,
                } = subscription;

                return (
                  <div key={index} className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">
                      Login Number: {login_number}
                    </h2>
                    <p className="text-lg">
                      Current Plan: <strong>{plan_type}</strong>
                    </p>
                    <p className="text-lg">
                      Expiry Date:{" "}
                      <strong>
                        {new Date(subscription_end_date).toLocaleDateString()}
                      </strong>
                    </p>
                    <p className="text-lg">
                      Status: <strong>{subscription_status}</strong>
                    </p>

                    {subscription_status === "Active" && (
                      <p className="font-semibold text-green-600">
                        Refresh the CashApp clone to have full functionalities
                      </p>
                    )}

                    {subscription_status !== "Active" && (
                      <div>
                        <label
                          htmlFor={`plan-${index}`}
                          className="block text-lg font-semibold mb-2"
                        >
                          Choose a subscription plan for {login_number}:
                        </label>
                        <select
                          id={`plan-${index}`}
                          value={selectedPlan}
                          onChange={(e) => setSelectedPlan(e.target.value)}
                          className="p-2 border border-gray-300 rounded max-w-custom mb-3 block"
                        >
                          <option value="Weekly">Weekly - ₦5000</option>
                          <option value="Monthly">Monthly - ₦10000</option>
                        </select>
                        <ActionButton
                          className="bg-blue-500 text-white py-2 px-4 rounded"
                          onClick={() =>
                            initializeSubscription(selectedPlan, login_number)
                          }
                          name={`Subscribe ${login_number} to ${selectedPlan} Plan`}
                        />
                      </div>
                    )}
                  </div>
                );
              } else {
                // If no subscription is found, show a placeholder and subscribe button
                return (
                  <div key={index} className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">
                      Login Number: {login_number}
                    </h2>
                    <p className="text-lg">Plan Type: --</p>
                    <p className="text-lg">Expiry Date: --</p>
                    <p className="text-lg">Status: --</p>

                    <div>
                      <label
                        htmlFor={`plan-${index}`}
                        className="block text-lg font-semibold mb-3"
                      >
                        Choose a subscription plan for {login_number}:
                      </label>
                      <select
                        id={`plan-${index}`}
                        value={selectedPlan}
                        onChange={(e) => setSelectedPlan(e.target.value)}
                        className="p-2 border border-gray-300 rounded max-w-custom mb-3 block"
                      >
                        <option value="Weekly">Weekly - ₦5000</option>
                        <option value="Monthly">Monthly - ₦10000</option>
                      </select>
                      <ActionButton
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                        onClick={() =>
                          initializeSubscription(selectedPlan, login_number)
                        }
                        name={`Subscribe ${login_number} to ${selectedPlan}`}
                      />
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <p>No login numbers found.</p>
          )}

          {/* Notification component */}
          {notification && (
            <Notification
              message={notification.message}
              type={notification.type}
              onClose={() => setNotification(null)}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <LoadingSpinner message={""} />;
  }
};

export default Subscription;
