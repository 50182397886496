// src/pages/Education.tsx
import React from "react";
import ResourceCard from "../components/ResourceCard"; // Reusing the ResourceCard component

const blogPosts = [
  {
    id: 1,
    title: "15 Examples of Real Social Engineering Attacks (2023)",
    description:
      "Learn about real-world social engineering attacks and how cybercriminals manipulate individuals.",
    link: "https://www.tessian.com/blog/examples-of-social-engineering-attacks/",
  },
  {
    id: 2,
    title: "How to Recognize and Resist Social Engineering Attacks",
    description:
      "Understand different types of social engineering attacks and how to defend against them.",
    link: "https://www.sentinelone.com/blog/social-engineering-attacks-how-to-recognize-and-resist-the-bait/",
  },
  {
    id: 3,
    title: "Building Effective Defenses Against Social Engineering",
    description:
      "A comprehensive guide on defending against social engineering through awareness, policies, and tech solutions.",
    link: "https://www.isaca.org/resources/news-and-trends/isaca-now-blog/2023/building-effective-defenses-against-social-engineering",
  },
  {
    id: 4,
    title:
      "Social Engineering in the Era of Generative AI: Predictions for 2024",
    description:
      "Explore how AI is transforming social engineering attacks and how to defend against them.",
    link: "https://securityintelligence.com/news/social-engineering-generative-ai-predictions-2024/",
  },
  {
    id: 5,
    title:
      "Safeguarding Yourself and Your Organization from Social Engineering Attacks",
    description:
      "Learn about common social engineering tactics and ways to safeguard yourself and your organization.",
    link: "https://blog.fincra.com/safeguarding-from-social-engineering-attacks/",
  },
];

const Education: React.FC = () => {
  return (
    <div>
      <section className="min-h-screen py-20 bg-gray-100">
        <div className="container mx-auto">
          {/* Introduction Section */}
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">
              Learn About Social Engineering
            </h2>
            <p className="text-gray-600 text-lg">
              Explore our curated blog posts to enhance your knowledge about
              social engineering, phishing attacks, and how to protect yourself.
            </p>
          </div>

          {/* Blog Post Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogPosts.map((post) => (
              <ResourceCard
                key={post.id}
                title={post.title}
                description={post.description}
                link={post.link}
              />
            ))}
          </div>

          {/* Call to Action */}
          <div className="text-center mt-12">
            <p className="text-lg text-gray-700">
              Stay updated on the latest security trends by subscribing to our
              newsletter!
            </p>
            <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Subscribe Now
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Education;
