// src/components/Notification.tsx
import React, { useEffect } from "react";

interface NotificationProps {
  message: string;
  type: "success" | "error"; // You can extend this for different types
  onClose: () => void; // Function to close the notification
}

const Notification: React.FC<NotificationProps> = ({
  message,
  type,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(); // Automatically close after 3 seconds
    }, 9000);

    return () => clearTimeout(timer); // Cleanup timer
  }, [onClose]);

  return (
    <div className="w-100">
      <div
        className={`fixed bottom-2 mx-auto  px-4 py-2 w-100 text-center self-center rounded-lg shadow-lg text-white ${
          type === "success" ? "bg-green-500" : "bg-red-500"
        }`}
      >
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Notification;
