import React from "react";

interface LoadingSpinnerProps {
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  message = "Loading, please wait...",
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="text-center">
        {/* Spinner */}
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid border-opacity-100 mx-auto"></div>

        {/* Message */}
        <p className="mt-4 text-white text-lg font-semibold">{message}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
