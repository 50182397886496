// src/pages/Tools.tsx
import React from "react";
import ToolCard from "../components/ToolCard";

const toolsData = [
  {
    id: 1,
    name: "App Clone (Simulated)",
    description:
      "A tool that replicates the interface of various types of apps, demonstrating how attackers can use fake apps to mimic legitimate platforms for social engineering purposes.",
    price: "₦5,000",
    link: "/tools/app-clone",
  },
  {
    id: 2,
    name: "Email Phishing Simulator",
    description:
      "A phishing tool that helps you understand how attackers trick users into giving up sensitive information via fraudulent emails.",
    price: "₦7,500",
    link: "/tools/email-phishing-simulator",
  },
  {
    id: 3,
    name: "Awareness Training Module",
    description:
      "Interactive learning tools for businesses to train their employees on social engineering risks and techniques.",
    price: "₦9,999",
    link: "/tools/awareness-training-module",
  },
  {
    id: 4,
    name: "Vishing Simulator",
    description:
      "A tool designed to simulate voice phishing (vishing) attacks, allowing users to experience how attackers manipulate targets via phone calls.",
    price: "₦9,999",
    link: "/tools/vishing-simulator",
  },
  {
    id: 5,
    name: "Spear Phishing Attack Simulator",
    description:
      "This tool simulates a targeted phishing attack, where an attacker focuses on specific individuals to steal sensitive information or gain unauthorized access.",
    price: "₦9,999",
    link: "/tools/spear-phishing-simulator",
  },
  {
    id: 6,
    name: "Baiting Attack Simulator",
    description:
      "A tool that replicates baiting attacks, demonstrating how attackers can lure victims with enticing offers to steal personal information or infect devices.",
    price: "₦9,999",
    link: "/tools/baiting-simulator",
  },
];

const Tools: React.FC = () => {
  return (
    <div>
      <section className="pt-20 bg-gray-100">
        <div className="mx-auto min-h-screen flex flex-col justify-between">
          <h2 className="text-3xl font-bold text-center mb-8">Our Tools</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-between px-2">
            {toolsData.map((tool) => (
              <ToolCard key={tool.id} tool={tool} />
            ))}
          </div>

          <div className="mt-8">
            <p className="text-center text-gray-600">
              Explore more tools to enhance your security awareness.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Tools;
