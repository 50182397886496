// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Tools from "./pages/Tools"; // Import the Tools pageç
import MyTools from "./pages/MyTools"; // Import the Tools pageç
import Education from "./pages/Education"; // Import the Tools pageç
import Contact from "./pages/Contact";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { ToolsProvider } from "./context/ToolsContext";
import Header from "./components/Header";
import TermsOfService from "./components/TermsOfService";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import DashboardHome from "./pages/DashboardHome";
import DashboardLayout from "./components/DashboardLayout";
import Subscription from "./pages/SubscriptionScreen";
import ToolPurchase from "./pages/ToolPurchase";
import AccessTool from "./pages/AccessTool";
import Referral from "./pages/Referral";
import ProtectedRoute from "./components/ProtectedRoute";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Profile from "./pages/Profile";

const App: React.FC = () => {
  if (window.location.hostname.startsWith("dashboard.")) {
    // Render the Dashboard routes
    return (
      <Router>
        <Routes>
          {/* Public Route */}
          <Route path="/login" element={<SignIn />} />
          <Route path="/Signup" element={<SignUp />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ToolsProvider>
                <UserProvider>
                  <ProtectedRoute>
                    <DashboardLayout />
                  </ProtectedRoute>
                </UserProvider>
              </ToolsProvider>
            }
          >
            <Route index element={<DashboardHome />} />
            <Route path="/my-tools" element={<MyTools />} />
            <Route path="/referrals" element={<Referral />} />
            <Route path="/support" element={<Contact />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/tools/:id/purchase" element={<ToolPurchase />} />
            <Route path="/my-tools/:id/access" element={<AccessTool />} />
            <Route path="/subscription-management" element={<Subscription />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    );
  }

  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tools" element={<Tools />} />{" "}
          <Route path="/education" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/dashboard" element={<DashboardHome />} /> */}
          <Route path="*" element={<NotFound />} />
          {/* Add the route for Tools */}
          {/* Add more routes as needed */}
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
