import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState(""); // State to store email input
  const [password, setPassword] = useState(""); // State to store password input
  const navigate = useNavigate(); // Hook for navigation after sign-in
  const [error, setError] = useState<string | null>(null); // State to manage login error
  const [loading, setLoading] = useState(false); // State to track loading status

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading to true when request starts

    if (!navigator.onLine) {
      setError("No internet connection. Please check your network.");
      setLoading(false); // Stop loading since there's no connection
    } else {
      try {
        const response = await fetch(
          "https://api.socengawareness.org/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              password: password,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Invalid email or password");
        }

        const data = await response.json();
        const token = data.access_token;
        localStorage.setItem("token", token);
        console.log(localStorage.getItem(token));
        setLoading(false); // Stop loading after success
        const redirectTo = localStorage.getItem("redirectTo") || "/"; // Default to home if no route is stored
        localStorage.removeItem("redirectTo"); // Clear the stored route
        navigate(redirectTo); // Redirect the user
      } catch (err: any) {
        if (err.message === "Invalid email or password") {
          setError("Invalid email or password");
          setLoading(false); // Stop loading if there's an error
        } else {
          setError("Our server is currently down. Please try again later.");
          setLoading(false); // Stop loading if there's an error
        }
      }
    }
  };

  return (
    <div className="px-2 bg-gray-800">
      <section className="min-h-screen py-12 flex justify-center items-center">
        <div className="bg-white p-8 shadow-lg rounded-lg w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-3 font-poppins">
            Log in to your account
          </h2>
          <p className="font-bold pb-3">
            Dont't have an account?
            <span>
              <Link
                to={"/signup"}
                className="text-blue-500 hover:underline px-1"
              >
                Sign Up
              </Link>
            </span>
          </p>

          {/* Display error message if login fails */}
          {error && (
            <div className="bg-red-100 text-red-700 p-4 mb-6 rounded">
              {error}
            </div>
          )}

          {/* Show spinner when loading */}
          {loading && (
            <div className="flex justify-center mb-6">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Email Input */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={loading} // Disable input when loading
              />
            </div>

            {/* Password Input */}
            <div>
              <label
                htmlFor="password"
                className="block text-gray-700 font-semibold"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading} // Disable input when loading
              />
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={loading} // Disable button when loading
              >
                {loading ? "Signing In..." : "Sign In"}{" "}
                {/* Show loading text */}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SignIn;
