import React from "react";

interface ResourceCardProps {
  title: string;
  description: string;
  link: string;
}

const ResourceCard: React.FC<ResourceCardProps> = ({
  title,
  description,
  link,
}) => {
  return (
    <div className="bg-white p-6 shadow-lg rounded-lg">
      <h4 className="text-xl font-bold mb-2">{title}</h4>
      <p className="text-gray-600 mb-4">{description}</p>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:text-blue-700 font-semibold"
      >
        Read More
      </a>
    </div>
  );
};

export default ResourceCard;
