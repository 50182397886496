import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext"; // Assuming you have a context to fetch user info
import { useTools } from "../context/ToolsContext"; // Assuming you have a context to fetch user info

const MyTools: React.FC = () => {
  const { user } = useUser();
  const { tools } = useTools();

  // Filter tools that the user has purchased by matching tool IDs
  const purchasedTools = tools.filter((tool) =>
    user.purchased_tools?.some((purchase: any) => purchase.tool_id === tool._id)
  );

  return (
    <div className="flex flex-wrap">
      <div className="hidden sm:block md:w-64"></div>
      <div className="p-8 flex-1 justify-center">
        <h1 className="text-3xl font-bold mb-4">Your Tools</h1>

        {purchasedTools.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
            {purchasedTools.map((tool) => (
              <div
                key={tool._id}
                className="bg-white p-6 shadow-lg rounded-lg flex flex-col  justify-between"
              >
                <div>
                  <h2 className="text-xl font-bold mb-2">{tool.name}</h2>
                  <p className="text-gray-600 mb-4">{tool.description}</p>
                </div>
                <div className="mt-auto">
                  <Link to={`/my-tools/${tool._id}/access`}>
                    <button className="bg-blue-500 text-white py-2 px-4 rounded w-full">
                      Access Tool
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <p className="text-center my-10">
              You haven't purchased any tools yet.
            </p>
            <div className="flex flex-col justify-center self-center">
              <button className="bg-blue-500 text-white py-2 px-4 rounded mx-auto ">
                <Link to={"/"}>Explore Available Tools</Link>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTools;
