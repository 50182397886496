import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import "./VideoPlayer.css"; // Import your CSS styles
import { useUser } from "../context/UserContext"; // Assuming you have a context for user info
import { useTools } from "../context/ToolsContext"; // Assuming you have a context for tools info

const AccessTool: React.FC = () => {
  const { id } = useParams(); // Get tool ID from URL
  const { user } = useUser(); // Get user info from context
  const { tools } = useTools(); // Get tools info from context
  const [showVideo, setShowVideo] = useState(false);

  // Find the purchased tool based on the tool ID
  const purchasedTool = user.purchased_tools.find(
    (purchase: any) => purchase.tool_id === id
  );

  // Find the corresponding tool data from the tool context
  const toolData = tools.find((tool) => tool._id === id);

  // If the tool is not found or the user hasn't purchased it, show an error or loading
  if (!purchasedTool || !toolData) {
    return <div>Tool not found or you do not have access.</div>;
  }
  const toggleVideo = () => {
    setShowVideo(!showVideo);
  };
  return (
    <div className="flex flex-wrap">
      <div className="hidden sm:block md:w-64"></div>
      <div className="p-8 flex-1 mt-10">
        <h1 className="text-3xl font-bold mb-6">{toolData.name}</h1>

        {/* Display the 11-digit login ID */}
        <div className="bg-gray-100 p-4 rounded-lg mb-4">
          <p className="text-gray-600 font-bold">
            Your login Number:{" "}
            <span className="text-blue-500">{purchasedTool.login_number}</span>
          </p>
          <p>You'll need this to log in to the CashApp</p>
        </div>

        {/* Instructions for using Expo Go */}
        <h2 className="text-xl font-semibold mt-4 " id="expo-download">
          How to Use the Tool
        </h2>
        <div className="pt-2">
          Don't get stucked, watch a quick tutorial on how to launch the tool on
          your device.
          <br />
          <button
            onClick={toggleVideo}
            className="text-blue-600 bg-gray-100 font-bold border rounded-lg py-2 px-3 my-2"
          >
            {showVideo ? "Hide Video" : "Show Video"}
          </button>
        </div>

        <div
          className={`mx-auto player-wrapper my-3 ${
            showVideo ? "" : "hidden"
          } `}
        >
          <ReactPlayer
            url="https://youtube-videos-demo.s3.us-west-2.amazonaws.com/how-to-launch-tool-in-expo.mp4"
            controls
            playing={true}
            width="auto"
            height="auto"
            className="react-player"
          />
        </div>

        <p className="mb-2">
          Follow the steps below to use the CashApp clone on your device:
        </p>
        <ol className="list-decimal ml-6 mb-4">
          <li>
            <strong>Download Expo Go:</strong>
            <span className=""> Download Expo Go</span> from the App Store or
            Google Play Store.
            <div className="flex space-x-4 mt-2">
              <p>
                <span className="text-gray-800 font-bold text-xl text-center block">
                  Android
                </span>
                <a
                  href="https://play.google.com/store/apps/details?id=host.exp.exponent&referrer=www"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/playstore.png"
                    alt="Download on Google Play"
                    className="w-32 mx-auto mb-5"
                  />
                  <span>
                    <img
                      src="/assets/images/expoappandriod.png"
                      alt="expo app"
                      width={200}
                      className="w-25 rounded-lg block"
                    />
                  </span>
                </a>
              </p>
              <p>
                <span className="text-gray-800 font-bold text-xl text-center block">
                  iPhone
                </span>

                <a
                  href="https://itunes.apple.com/app/apple-store/id982107779"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/appstore.webp"
                    alt="Download on the App Store"
                    className="w-32 mx-auto mb-5"
                  />
                  <span>
                    <img
                      src="/assets/images/expoapp.png"
                      alt="expo app"
                      width={200}
                      className="w-25 rounded-lg block"
                    />
                  </span>
                </a>
              </p>
            </div>
          </li>
          <li className="mt-4">
            <strong>Launch the tool in Expo Go:</strong> Click Launch tool or
            Use your phone's camera to scan the QR code below.
          </li>
        </ol>
        <div>
          <p className="font-semibold">
            Make sure you have{" "}
            <span>
              <a
                href="#expo-download"
                className="text-blue-600 hover:underline"
              >
                expo go
              </a>
            </span>{" "}
            app installed on your phone
          </p>
          <p className="mt-3 mb-7">
            Click here: {"  "}
            <a
              href="exp://u.expo.dev/afe8100e-329a-47fe-a044-2edcf3536387/group/8ef54567-d88e-4619-95cc-a108cf1fc33c"
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 bg-gray-100 font-bold border rounded-lg p-2"
            >
              Launch tool
            </a>
          </p>
        </div>

        {/* Placeholder for QR code (replace with actual QR code in practice) */}
        <div className=" mb-4 px-2 hidden">
          <img
            src="/assets/images/expo-qr.png"
            alt="QR Code for CashApp Clone"
            className="border rounded-lg"
            width={300}
          />
        </div>

        <p className="text-gray-600 mb-4">
          Once Launched, follow the prompts to access the CashApp clone.
        </p>
      </div>
    </div>
  );
};

export default AccessTool;
