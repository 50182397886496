// src/components/ToolCard.tsx
import React from "react";
// import { Link } from "react-router-dom";
// import { useAuth } from "../context/AuthContext"; // Import the auth context for authentication check
import { useNavigate } from "react-router-dom"; // Use navigate for redirection

interface Tool {
  id: number;
  name: string;
  description: string;
  price: string;
  link: string;
}

interface ToolCardProps {
  tool: Tool;
}

// const handleSignInClick = (e: any) => {
//   e.preventDefault();
//   const currentHost = window.location.host; // Gets the current host (e.g., localhost:3000)

//   // Check if it's already a dashboard subdomain, if not, append it
//   if (!currentHost.startsWith("dashboard")) {
//     const newHost = `dashboard.${currentHost}`;
//     window.location.href = `http://${newHost}/login`; // Redirect to the subdomain with /login
//   } else {
//     navigate("/login"); // If already on dashboard subdomain, just navigate to login
//   }
// };

const ToolCard: React.FC<ToolCardProps> = ({ tool }) => {
  // const { isAuthenticated } = useAuth(); // Get the auth status
  const navigate = useNavigate();

  const handleLearnMore = () => {
    const currentHost = window.location.host; // Gets the current host (e.g., localhost:3000)

    // Check if it's already a dashboard subdomain, if not, append it
    if (!currentHost.startsWith("dashboard")) {
      const newHost = `dashboard.${currentHost}`;
      window.location.href = `http://${newHost}/login`; // Redirect to the subdomain with /login
    } else {
      navigate("/login"); // If already on dashboard subdomain, just navigate to login
    }
  };

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg">
      <h3 className="text-xl font-bold mb-2">{tool.name}</h3>
      <p className="text-gray-600 mb-4">{tool.description}</p>
      <p className="text-lg font-semibold mb-4">{tool.price}</p>
      <button
        onClick={handleLearnMore}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Learn More
      </button>
    </div>
  );
};

export default ToolCard;
